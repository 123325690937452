import React from 'react'
import Img from 'gatsby-image'
import tw from 'twin.macro'
import { Link } from 'gatsby'

const BlogCard = ({link, image, image_title, title, date, description, category, category_link, html}) => {

  const ImageContainer = tw.div`
    w-full
    h-custom
    xl:h-blog
    overflow-hidden
    bg-cygrey
    `

    return (
      
      <article key={title} tw=" w-full md:w-2/5 overflow-hidden my-6 md:mx-3 rounded bg-cylightblue bg-opacity-25 border-solid border-cylightblue border">
          <Link to={link}>
            <ImageContainer>
              <Img fluid={image}
                alt={image_title}
                tw="bg-cover bg-center h-full rounded transition-opacity duration-500 ease-in-out hover:opacity-25"
              />
            </ImageContainer>
          </Link>
          <header tw="px-3">
          <div tw="flex flex-row justify-between">
            <div tw=" text-gray-500 text-xs uppercase font-bold tracking-wider py-3">{date}</div>
              <Link to={`/category/${category_link}`}>
                <div tw="text-cypink text-xs uppercase font-bold tracking-wider py-3 hover:text-cyred">
                  {category}
                </div>
              </Link>
            </div>  

              <h3>
                <Link to={link}>
                  {title}
                </Link>
              </h3>
            </header>
            <section tw="px-3 py-1 flex-grow">
            </section>
            
      </article>
    )

}

export default BlogCard

