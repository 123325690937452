import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import tw, { styled } from 'twin.macro'

import SEO from "../components/seo"
import BlogCard from "../components/blog_card"


const PostWrap = tw.div`
flex
flex-row
flex-wrap
justify-center
`

const CategoryPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const categoryName = data.allContentfulCategory.nodes[0].name
  const posts = data.allContentfulCategory.nodes[0].blog_post

  return (
    <Layout location={location} title={siteTitle} logoImage={data.logoImage}>
      <SEO title="The Culture Yard Blog by category" description={categoryName} />
      <h1 tw="text-center">The Culture Yard Blog</h1>
      <h3 tw="text-center">Category: {categoryName}</h3>
      <PostWrap>
        {posts && posts.map(post => {
          return (
            <BlogCard
              title={post.title}
              image={post.featureImage.fluid}
              image_title={post.featureImage.description}
              link={`/blog/${post.slug}`} date={post.publishDate}
              description={post.description}
              html= {post.description === null ?  "Description" : post.description.description }
              category={post.category.name}
              category_link={post.category.slug}
              />
            )
        })}
      </PostWrap>
    </Layout>
  )
}
export default CategoryPage

export const pageQuery = graphql`
  query CategoryPageQuery($category: String) {
    site {
      siteMetadata {
        title
      }
    }
    logoImage:contentfulAsset(title: {eq: "logo"}) {
      fluid(quality: 100, maxWidth: 300) {
        ...GatsbyContentfulFluid
      }
    }
    allContentfulCategory(
      filter: {slug: {eq: $category}}
      sort: { fields: slug, order: DESC }
    ) {
        nodes {
            name
            blog_post {
            id
            category{
              name
              slug
            }
            slug
            title
            featureImage {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyContentfulFluid
              }
            }
            author {
              name
              node_locale
            }
            body {
              body
            }
            publishDate(formatString: "MMMM DD, YYYY")
            description {
              description
            }
          }
        }
      }
    }
`